import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ThreeDSGlobalPage from './pages/ThreeDSGlobalPage';
import ThreeDSGlobalCompleteAuthPage from './pages/ThreeDSGlobalCompleteAuthPage';
import { WorldPayChallengeComponent, WorldPayDCCComponent } from './components/WorldPayComponent';
import './App.css';

const HomePage = () => {
  return <div>&nbsp;</div>;
};

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />

        {/*  MPI */}
        <Route path="global" element={<ThreeDSGlobalPage />} />
        <Route path="global/complete" element={<ThreeDSGlobalCompleteAuthPage />} />

        {/*  WorldPay */}
        <Route path="worldpay/dcc" element={<WorldPayDCCComponent />} />
        <Route path="worldpay/challenge" element={<WorldPayChallengeComponent />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
