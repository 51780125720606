import React from 'react';
import ThreeDSGlobalPageComponent from '../components/ThreeDSGlobalPageComponent';
import { use3dsOrchestration } from '../hooks/use-3ds-orchestration';

const ThreeDSGlobalPage = () => {
  use3dsOrchestration();

  return <ThreeDSGlobalPageComponent />;
};

export default ThreeDSGlobalPage;
