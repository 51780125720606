import React, { useCallback, useEffect, useRef } from 'react';
import axios from 'axios';

import { parseJSON } from '../../utils/parseJSON';
import { useGetQueryParams } from '../../hooks/use-get-query-params';
import { WORLD_PAY_DCC_PARAMS_GETTER, WORLD_PAY_POST_EVENT_ORIGINS } from './constant';

const API_URL = process.env.REACT_APP_WORLDPAY_API_URL;

const CALLBACK_URL = `${API_URL}/device-data-initialise-callback`;

export const WorldPayDCCComponent = () => {
  const formRef = useRef();

  const { deviceDataCollectionUrl, jwt, bin, transactionReference, connectionId } =
    useGetQueryParams(WORLD_PAY_DCC_PARAMS_GETTER);

  const handleMessage = useCallback(
    async (event) => {
      if (WORLD_PAY_POST_EVENT_ORIGINS.includes(event.origin)) {
        const { SessionId } = parseJSON(event.data);

        try {
          const callbackResponse = await axios.post(
            `${CALLBACK_URL}/${transactionReference}/${SessionId}/${connectionId}`
          );

          window.location = callbackResponse.data;
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('DCC callback', e);
        }
      }
    },
    [transactionReference, connectionId]
  );

  useEffect(() => {
    formRef.current?.submit();

    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  }, [handleMessage]);

  return (
    <div>
      <iframe height="300" width="300" title="worldpay-dcc" name="worldpay-dcc" />
      <form
        ref={formRef}
        id="collectionForm"
        name="devicedata"
        method="POST"
        action={deviceDataCollectionUrl}
        target="worldpay-dcc"
      >
        <input type="hidden" name="Bin" value={bin} />

        <input type="hidden" name="JWT" value={jwt} />
      </form>
    </div>
  );
};
