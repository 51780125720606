import { useState, useEffect, useCallback } from 'react';
import { post3dsMethod, callbackStatus, creq } from '../constant';

export const use3dsOrchestration = () => {
  const [startData, setStartData] = useState(undefined);
  const [initialData, setInitialData] = useState(undefined);
  const [callbackReceived, setCallbackReceived] = useState(false);
  const [threeDSMethodURLPresent, setThreeDSMethodURLPresent] = useState(false);

  const handleStart = useCallback(async (paramsData) => {
    setThreeDSMethodURLPresent(paramsData.threeDSMethodURL !== null);
    let startResult;
    if (paramsData.threeDSMethodURL) {
      startResult = await post3dsMethod(paramsData);
      setStartData(startResult);
    } else {
      setStartData({
        threeDSServerTransID: paramsData.threeDSServerTransID,
        ourTransactionId: paramsData.ourTransactionId,
        connectionId: paramsData.connectionId,
        lookupValue: paramsData.lookupValue,
      });
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    const paramsData = {
      threeDSMethodURL: queryParams.get('threeDSMethodURL'), // optional field
      threeDSServerTransID: queryParams.get('threeDSServerTransID'),
      threeDSMethodNotificationURL: queryParams.get('threeDSMethodNotificationURL'),
      ourTransactionId: queryParams.get('ourTransactionId'),
      connectionId: queryParams.get('connectionId'),
      lookupValue: queryParams.get('lookupValue'),
    };

    // if a value is missing, we will not be able to proceed
    if (
      !paramsData.threeDSServerTransID ||
      !paramsData.ourTransactionId ||
      !paramsData.connectionId ||
      !paramsData.threeDSMethodNotificationURL ||
      !paramsData.lookupValue
    )
      return;

    setInitialData(paramsData);
    handleStart(paramsData);
  }, [handleStart]);

  const handleCallback = useCallback(async () => {
    const callbackComplete = await callbackStatus(
      initialData.ourTransactionId,
      initialData.connectionId,
      initialData.lookupValue
    );

    if (callbackComplete.callbackReceived) {
      setCallbackReceived(true);
    }
  }, [initialData]);

  // poll callback request
  // TODO: if no response in 30 seconds, show error
  useEffect(() => {
    if (!threeDSMethodURLPresent || !startData || callbackReceived) {
      return;
    }

    const interval = setInterval(() => {
      if (startData && !callbackReceived) {
        handleCallback();
      }
    }, 1000);
    setTimeout(() => {
      clearInterval(interval);
    }, 30000);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(interval);
  }, [startData, callbackReceived, handleCallback, threeDSMethodURLPresent]);

  const handleCreq = useCallback(async () => {
    await creq(startData);
  }, [startData]);

  useEffect(() => {
    if (startData === undefined || (threeDSMethodURLPresent && !callbackReceived)) {
      return;
    }

    handleCreq();
  }, [callbackReceived, handleCreq, threeDSMethodURLPresent, startData]);

  // last step

  const handleMessageEvent = useCallback((event) => {
    if (event.data.redirectUrl) {
      window.location = event.data.redirectUrl.trim();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', handleMessageEvent);
    return window.addEventListener('message', handleMessageEvent);
  }, [handleMessageEvent]);
};
