import React, { useEffect } from 'react';

const ThreeDSGlobalCompleteAuthPage = () => {
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    // Base64-decode the redirect_url - it is encoded by the server to avoid issues parsing the query params on this side
    const redirectUrl = atob(queryParams.get('redirect_url'));

    const actionType = queryParams.get('action_type') || 'POST_TO_PARENT';

    // Dev note:
    // If either of the 3DS iframes (threeDSMethodIframe, challengeIframe) were used then change parent window
    // location to the redirectUrl, otherwise change this window location to the redirectUrl. The latter case can
    // happen if 3DS-2 is not supported for the PAN in question or an error occurs before loading a 3DS iframe.

    if (actionType === 'POST_TO_PARENT') {
      window.parent.postMessage(
        {
          redirectUrl,
        },
        '*'
      );
    } else {
      window.location = redirectUrl;
    }
  }, []);

  // we dont need to show anything
  return <div>&nbsp;</div>;
};

export default ThreeDSGlobalCompleteAuthPage;
