/**
 *
 * @param {object} param
 * @param {string} param.key
 * @param {(string) => any } param.parser
 * @param {param[]} paramsToGet
 */

export const useGetQueryParams = (paramsToGet) => {
  const params = new URLSearchParams(window.location.search);

  if (!Array.isArray(paramsToGet)) return {};

  const getParams = paramsToGet.reduce((acc, param) => {
    const value = params.get(param.key);

    if (value) {
      if (param.parser) {
        return { ...acc, [param.key]: param.parser(value) };
      }

      return { ...acc, [param.key]: value };
    }

    return acc;
  }, {});

  return getParams;
};
