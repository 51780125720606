import React from 'react';

const HomePage = () => {
  return (
    <div className="container">
      {/* <button type="button" onClick={handleStart}>
                Start and Post 3DS Method
            </button>

            <button type="button" onClick={handleCallback}>
                Check callback status
            </button>

            <button type="button" onClick={handleCreq}>
                Auth and Post to CREQ
            </button> */}

      {/** Method frame */}
      <div id="3ds-method" />
      <iframe
        name="threeDSMethodIframe"
        id="threeDSMethodIframe"
        title="threeDSMethodIframe"
        // eslint-disable-next-line no-inline-styles/no-inline-styles
        style={{ visibility: 'hidden', display: 'none' }}
        className="hidden"
      />
      <form id="threeDSMethodForm">
        <input type="hidden" name="threeDSMethodData" id="threeDSMethodData" />
      </form>

      {/** Challenge frame */}
      <iframe name="challengeIframe" id="challengeIframe" title="challengeIframe" width="636px" height="600px" />
      <form id="challengeForm">
        <input type="hidden" name="threeDSSessionData" id="threeDSSessionData" />
        <input type="hidden" name="creq" id="creq" />
      </form>
    </div>
  );
};

export default HomePage;
