export const WORLD_PAY_DCC_PARAMS_GETTER = [
  {
    key: 'deviceDataCollectionUrl',
  },
  {
    key: 'jwt',
  },
  {
    key: 'bin',
  },
  {
    key: 'transactionReference',
  },
  {
    key: 'connectionId',
  },
];

export const WORLD_PAY_CHALLENGE_PARAMS_GETTER = [
  {
    key: 'challengeUrl',
  },
  {
    key: 'jwt',
  },
  {
    key: 'transactionReference',
  },
  {
    key: 'challengeReference',
  },
  {
    key: 'connectionId',
  },
];

export const WORLD_PAY_POST_EVENT_ORIGINS = [
  'https://secure-test.worldpay.com',
  'https://centinelapistag.cardinalcommerce.com',
  'https://centinelapi.cardinalcommerce.com',
];
