import React, { useRef, useEffect } from 'react';
import { useGetQueryParams } from '../../hooks/use-get-query-params';
import { WORLD_PAY_CHALLENGE_PARAMS_GETTER } from './constant';

export const WorldPayChallengeComponent = () => {
  const { challengeUrl, jwt, transactionReference, challengeReference, connectionId } = useGetQueryParams(
    WORLD_PAY_CHALLENGE_PARAMS_GETTER
  );

  const formRef = useRef();

  const postFormData = {
    transactionReference,
    challengeReference,
    connectionId,
  };

  const postFormDatab64 = btoa(JSON.stringify(postFormData)).replace(/={1,2}$/g, '');

  const handleMessageEvent = (event) => {
    if (event.data.redirectUrl) {
      window.location = event.data.redirectUrl.trim();
    }
  };

  useEffect(() => {
    formRef.current?.submit();

    window.addEventListener('message', handleMessageEvent);

    return () => window.removeEventListener('message', handleMessageEvent);
  }, []);

  return (
    <div>
      <iframe height="600" width="400" title="worldpay-auth" name="worldpay-auth" />
      <form ref={formRef} id="challengeForm" method="POST" action={challengeUrl} target="worldpay-auth">
        <input type="hidden" name="JWT" value={jwt} />
        <input type="hidden" name="MD" value={postFormDatab64} />
      </form>
    </div>
  );
};
