import axios from 'axios';

const endpoint = process.env.REACT_APP_GLOBAL_API_URL;

export const post3dsMethod = async (initialData) => {
  try {
    const {
      threeDSMethodURL,
      threeDSServerTransID,
      threeDSMethodNotificationURL,
      ourTransactionId,
      connectionId,
      lookupValue,
    } = initialData;

    const postFormData = {
      threeDSServerTransID,
      threeDSMethodNotificationURL,
    };

    const form = document.getElementById('threeDSMethodForm');
    const input = document.getElementById('threeDSMethodData');
    input.value = btoa(JSON.stringify(postFormData)).replace(/={1,2}$/g, '');

    form.action = threeDSMethodURL;
    form.target = 'threeDSMethodIframe'; // id of iframe
    form.method = 'post';
    form.submit();

    return {
      threeDSMethodURL,
      threeDSServerTransID,
      threeDSMethodNotificationURL,
      ourTransactionId,
      connectionId,
      lookupValue,
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('post3dsMethod error', error);

    return null;
  }
};

export const callbackStatus = async (ourTransactionId, connectionId, lookupValue) => {
  try {
    const result = await axios.get(
      `${endpoint}/payments/global/3DSecure/3ds-method-callback/${ourTransactionId}/${connectionId}/${lookupValue}`
    );
    return result.data;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('callbackStatus error', e);

    return null;
  }
};
const auth = async ({ transId, ourTransactionId, connectionId, lookupValue }) => {
  try {
    const ipResult = await axios.post('https://wobk124xua.execute-api.us-west-2.amazonaws.com/dev/get-ip');
    const result = await axios.post(
      `${endpoint}/payments/global/3DSecure/3ds-auth/${ourTransactionId}/${connectionId}/${lookupValue}`,
      {
        transId,
        browserJavaEnabled: window.navigator.javaEnabled(),
        browserJavascriptEnabled: true,
        browserLanguage: window.navigator.language,
        browserColorDepth: window.screen.colorDepth,
        browserScreenHeight: window.screen.height,
        browserScreenWidth: window.screen.width,
        browserTZ: new Date().getTimezoneOffset(),
        browserUserAgent: window.navigator.userAgent,
        browserIP: ipResult.data.ipAddress,
      }
    );
    return result.data;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Auth error', e);

    return null;
  }
};

export const creq = async (startData) => {
  try {
    const { acsTransId, acsUrl, messageVersion, threeDSServerTransId, lookupValue } = await auth({
      transId: startData?.threeDSServerTransID,
      ourTransactionId: startData?.ourTransactionId,
      connectionId: startData?.connectionId,
      lookupValue: startData?.lookupValue,
    });

    const creqPayload = {
      threeDSServerTransId,
      acsTransId,
      messageVersion,
      messageType: 'CReq',
      challengeWindowSize: '04',
    };

    const form = document.getElementById('challengeForm');
    const input = document.getElementById('creq');
    const additionalData = document.getElementById('threeDSSessionData');

    // This is data that will be passed back to our backend during the callback
    const additionalDataPayload = {
      lookupValue,
    };
    additionalData.value = btoa(JSON.stringify(additionalDataPayload)).replace(/={1,2}$/g, '');
    input.value = btoa(JSON.stringify(creqPayload)).replace(/={1,2}$/g, '');

    form.action = acsUrl;
    form.target = 'challengeIframe'; // id of iframe
    form.method = 'post';
    form.submit();
    return null;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('creq error', e);

    return null;
  }
};
